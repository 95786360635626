<template>
    <div class="storages-modal">
        <div class="storages-list" v-for="(location, n) in storages" :key="n">
            <div class="location-content">
                <h4>{{ location.pseudo }}</h4>

                <div class="location-address">
                    <div class="location-address-distance">
                        <img
                            src="@/assets/svg/v2/settings/billingDelivery.svg"
                            alt="Distance"
                            width="16"
                            height="16"
                        />
                        {{ location.city }}
                    </div>
                    <div>
                        <p>{{ location.region ? location.region + ', ' : '' }} {{ location.country }}</p>
                    </div>
                </div>
            </div>

            <div class="location-action">
                <button @click="selectLocation(location)" class="generic-btn">{{ $t('commons.select') }}</button>
            </div>
        </div>
    </div>
</template>
  
<script>
import { mapGetters } from 'vuex'

 export default {
    emits: ['storageSelected'],
    props: {
        storages: {
            type: Object,
            required: true
        }
    },
    data() {
      return {}
    },
    computed: {
        ...mapGetters({}),
    },
    methods: {
        selectLocation(location) {
            this.$emit('storageSelected', location);
        }
    }
}
</script>
  
<style scoped lang="scss">
@import '@/assets/styles/root.scss';

.storages-modal {

    .storages-list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: var(--background-color);
        padding: 10px;
        border-radius: 10px;
        margin-bottom: 10px;

        .location-content {

            h4 {
                margin-bottom: 8px;
            }

        }

        .location-action {

            button {
                width: 120px;
            }
        }
    }
}

.location-address {
    display: flex;
    align-items: end;
    margin-bottom: 8px;

    .location-address-distance {
        display: flex;
        align-items: center;
        background-color: var(--backgroud-color-2);
        border-radius: 8px;
        padding: 2px 5px;
        margin-right: 10px;
    }
}

.el-checkbox__label {
    color: var(--text-color);
}
</style>
  